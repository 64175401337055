<template>
  <section class="hero is-fullheight">
    <ModalChangePassword
      v-if="modalChangePasswordActive"
      :active="modalChangePasswordActive"
      @close-modal="modalChangePasswordActive = false"
      :loading="loadingModal"
      @set-action-update-password="setActionUpdatePassword"
    />
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Mi perfil
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <div class="block">
                    <p class="font_20 has_lightgrey has-text-weight-bold">
                      Datos Generales
                    </p>
                  </div>
                  <div class="block">
                    <template v-if="modeEdit">
                      <div class="field">
                        <div class="control">
                          <p class="font_15 has-text-weight-semibold has_gray mb-2">
                            Nombre
                          </p>
                          <input
                            class="input px-3 py-3"
                            type="text"
                            placeholder="Nombre"
                            v-model="userName"
                            name="userName"
                            v-validate="'alpha_spaces|required'"
                            data-vv-as="del nombre"
                          >
                          <span v-show="vverrors.has('userName')" class="help is-danger">{{ vverrors.first('userName') }}</span>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <p class="font_15 has_gray has-text-weight-semibold">
                        Nombre: <span class="has-text-weight-normal has_text_gray">{{userName}}</span>
                      </p>
                    </template>
                  </div>
                  <div class="block">
                    <p class="font_15 has_gray has-text-weight-semibold">
                      Correo electrónico: <span class="has-text-weight-normal has_text_gray">{{email}}</span>
                    </p>
                  </div>
                  <div class="block has-text-centered mt-6">
                    <template v-if="!modeEdit">
                      <button class="button btn_violet btn_w" @click="modeEdit = true">
                        Editar
                      </button>
                    </template>
                    <template v-else>
                      <div class="buttons is-justify-content-center is-align-items-center">
                        <button
                          class="button is_outlined btn_w"
                          :disabled="loading"
                          @click="modeEdit = false"
                        >
                          Cancelar
                        </button>
                        <button
                          class="button btn_violet btn_w"
                          :disabled="loading"
                          :class="{ 'is-loading' : loading }"
                          @click="updateProfileAction"
                        >
                          Guardar
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-6">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <div class="columns is-multiline is-vcentered">
                    <div class="column is-12">
                      <p class="font_20 has_lightgrey has-text-weight-bold">
                        Privacidad
                      </p>
                    </div>
                    <div class="column is-12 has-text-centered">
                      <button class="button btn_violet" @click="modalChangePasswordActive = true">
                        Cambiar contraseña
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProfileMain',
  components: {
    ModalChangePassword: () => import('@/components/Profile/ModalChangePassword.vue')
  },
  data () {
    return {
      modeEdit: false,
      loading: false,
      loadingModal: false,
      modalChangePasswordActive: false,
      userName: null
    }
  },
  computed: {
    ...mapState({
      email: state => state.authentication.email,
      name: state => state.authentication.name
    })
  },
  methods: {
    ...mapActions(['changePassword', 'myProfileUpdate', 'validateSession']),
    async setActionUpdatePassword (data) {
      this.loadingModal = true
      const resp = await this.changePassword(data)
      if (resp) {
        this.modalChangePasswordActive = false
      }
      this.loadingModal = false
    },
    async updateProfileAction () {
      this.loading = true
      const dataUpdate = {
        userName: this.userName
      }
      const resp = await this.myProfileUpdate(dataUpdate)
      if (resp) {
        this.modeEdit = false
        await this.validateSession()
        this.userName = this.name
      }
      this.loading = false
    }
  },
  beforeMount () {
    this.userName = this.name
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }
  .card-header {
    box-shadow: none;
    border-bottom: solid 1px #d5d5d5;
  }
  .card-header-title {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
  }
  .btn_w {
    width: 150px;
  }
</style>
